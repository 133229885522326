import styled from "styled-components";
import { mediaQuery } from "../../assets/styles/mixins";

export const Container = styled.article`
  margin-bottom: 70px;
  display: flex;
  align-items: flex-start;
  text-align: left;

  ${mediaQuery.desktop`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  `}

  img {
    width: 13.96%;
    text-align: left;
    margin-top: 6.5px;

    ${mediaQuery.desktop`
      width: 80px;
      text-align: center;
      margin-bottom: 30px;
    `}
  }

  .text-wrapper {
    width: 86.04%;
    margin-left: 7.541%;

    ${mediaQuery.desktop`
      width: auto;
      margin-left: inherit;
    `}
  }

  h3 {
    color: ${({ theme }) => theme.font.second};
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;

    ${mediaQuery.desktop`
      font-size: 24px;
    `}
  }

  p {
    color: ${({ theme }) => theme.font.primary};
    font-size: 16px;

    ${mediaQuery.desktop`
        max-width: 380px;
        margin: 0 auto;
    `}
  }
`;
