import React from "react";

import { Container } from "./styles";

import { CardProps } from "./interface";

const Card = ({ image, altImage, title, subtitle, text }: CardProps) => {
  return (
    <Container className="col-md-5">
      <img
        src={image}
        alt={altImage}
        style={{ height: "80px", width: "80px" }}
      />
      <div className="text-wrapper">
        <h3>{title} <br/> {subtitle} </h3>
        <p>{text}</p>
      </div>
    </Container>
  );
};

export default Card;
